// EXEMPT
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.js";

import { IS_APP } from "@/__main__/constants.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import DownloadButtonWrapper from "@/app/DownloadButtonWrapper.jsx";
import { getBungieOAuthProps } from "@/game-destiny2/utils/redirect-to-bungie.mjs";
import ArrowRightIcon from "@/inline-assets/arrow-right2.svg";
import Container from "@/shared/ContentContainer.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader";
import { useDownloadLink } from "@/util/download-app.mjs";

const Styled = {
  content: () => css`
    --game-color: #efba52;

    & {
      display: grid;
      gap: var(--sp-6);
    }

    .hero {
      width: 100%;
      height: auto;
      aspect-ratio: 1016 / 218;
    }

    .center {
      width: 100%;
      max-width: 70ch;
      margin-inline: auto;
    }

    p {
      color: var(--shade1);

      a {
        cursor: pointer;
        color: var(--shade0);
        font-weight: 550;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    hr {
      width: 100%;
      height: 0;
      margin-block: var(--sp-4);
      border: none;
      border-bottom: 1px solid var(--shade3-25);
    }

    .download-link {
      color: var(--primary);
      font-weight: 700;
      text-decoration: underline;
    }

    details {
      > div,
      > p {
        padding: var(--sp-6);
        color: var(--shade1);
      }

      &[open] {
        summary {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          box-shadow: inset 0 -1px var(--card-inset-border);
        }
      }
    }
    summary {
      cursor: pointer;
      padding: var(--sp-4);
      background: var(--shade7);
      border-bottom-right-radius: var(--card-radii);
      border-bottom-left-radius: var(--card-radii);
      user-select: none;
    }

    .auth-btn {
      background-color: var(--game-color);
      font-size: 1rem;
      font-weight: 600;
    }
  `,
};

function FAQ() {
  const { t } = useTranslation();
  const downloadUrl = useDownloadLink();

  return (
    <PageContainer>
      {!IS_APP && (
        <PageHeader
          title={t("destiny2:faq.blitzForDestiny", "Blitz for Destiny 2 FAQ")}
        />
      )}
      <Container className={Styled.content()}>
        <Card padding="0">
          <img
            src={`${appURLs.CDN}/blitz/destiny2/misc/faq/destiny-faq-hero_2.webp`}
            className="hero"
            width="1016"
            height="218"
            alt="Blitz for Destiny 2"
          />
        </Card>
        {!IS_APP && (
          <>
            <p className="center">
              <Trans i18nKey="destiny2:faq.appRequired">
                The Blitz Desktop app is required for tracking your matches. If
                you don&apos;t already have Blitz installed be sure to{" "}
                <a href={downloadUrl} className="download-link">
                  download now
                </a>
                !
              </Trans>
            </p>
            <DownloadButtonWrapper
              uiLocation="DESTINY2_FAQ_WEB_CTA"
              noClassOverride
              emphasis="high"
              iconRight={<ArrowRightIcon />}
              size="large"
              textColor="var(--shade0)"
              style={{ width: "fit-content", marginInline: "auto" }}
            >
              {t("common:getBlitzCTA", "Get Blitz Now")}
            </DownloadButtonWrapper>
            <hr />
          </>
        )}
        <p className="center">
          <Trans i18nKey="destiny2:faq.takeControl">
            Take control of your loadout with Blitz.gg’s Item Manager for
            Destiny 2. Seamlessly manage and transfer your weapons, armor, and
            inventory between characters to create the ultimate loadout.
          </Trans>
        </p>
        <hr />
        <h2 className="type-h3 center">
          {t("destiny2:faq.howItWorks", "How It Works")}
        </h2>
        <h3 className="type-h5 center">
          {t("destiny2:faq.connect.title", "1. Connect Your Account")}
        </h3>
        <p>
          <Trans i18nKey="destiny2:faq.connect.copy_2">
            Easily <a {...getBungieOAuthProps()}>connect your Bungie account</a>{" "}
            to access all of your in-game items and gameplay information. Our
            system securely integrates with the Bungie API, allowing you to
            manage your inventory across all characters and activities in real
            time.
          </Trans>
        </p>
        <div className="w-full flex justify-center">
          <Button
            className="auth-btn"
            textColor="var(--shade10)"
            size={"large"}
            {...getBungieOAuthProps()}
          >
            {t("destiny2:auth:button", "Auth with Bungie")}
          </Button>
        </div>
        <h3 className="type-h5 center">
          {t(
            "destiny2:faq.organize.title",
            "2. Organize and Search Your Inventory",
          )}
        </h3>
        <p>
          <Trans i18nKey="destiny2:faq.organize.copy">
            With our intuitive interface, you can quickly search, vault, and
            equip items across your entire inventory with the click of a button.
          </Trans>
        </p>
        <h3 className="type-h5 center">
          {t("destiny2:faq.equip.title", "3. Equip the Perfect Loadout")}
        </h3>
        <p>
          <Trans i18nKey="destiny2:faq.equip.copy">
            Optimize your gameplay by building custom loadouts for specific
            activities like raids, strikes, or PvP.
          </Trans>
        </p>
        <h3 className="type-h5 center">
          {t("destiny2:faq.comingSoon.title", "(Coming Soon)")}
        </h3>
        <p>
          <Trans i18nKey="destiny2:faq.comingSoon.copy">
            Instantly equip the best gear for your playstyle with one click,
            save and share loadouts for future use, and try builds shared around
            the community.
          </Trans>
        </p>
        <h3 className="type-h5 center">
          {t("destiny2:faq.transfer.title", "4. Transfer Between Characters")}
        </h3>
        <p>
          <Trans i18nKey="destiny2:faq.transfer.copy">
            Need to switch gear between characters? Easily transfer items
            between your characters or to the vault without logging in and out,
            our tool automatically ensures no item duplication or loss.
          </Trans>
        </p>
        <h3 className="type-h5 center">
          {t("destiny2:faq.advFeatures.title", "5. Advanced Features")}
        </h3>
        <p>
          <Trans i18nKey="destiny2:faq.advFeatures.copy">
            Utilize your profile page for more advanced features like mission
            summaries, stat tracking, and more to ensure you’re always using the
            best possible equipment.
          </Trans>
        </p>
      </Container>
    </PageContainer>
  );
}

export default FAQ;

export function meta() {
  return {
    title: ["destiny2:faq.blitzForDestiny", "Blitz for Destiny 2 FAQ"],
    description: [],
  };
}
